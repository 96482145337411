import React from 'react';
import Link from 'next/link';
import LogoSvg from '../../assets/img/techery-text-logo.inline.svg';
import LogoBlogSvg from '../../assets/img/techery-blog-logo.inline.svg';
import JobsLogoSvg from '../../assets/img/techery-jobs-logo.inline.svg';
import styles from './header.module.scss';

export const urlToLogoMap = {
  '/blog': () => (
    <Link href="/blog" className={styles.logoLink}>
      <LogoBlogSvg />
    </Link>
  ),
  '/jobs': () => (
    <Link href="/" className={styles.logoLink}>
      <JobsLogoSvg />
    </Link>
  ),
  '/': () => (
    <Link href="/" className={styles.logoLink}>
      <LogoSvg />
    </Link>
  ),
};

export const jobsPageLinks = <></>;
